// TABLE OF CONTENTS
//=============================================================================
// 0. Variables
// 1. Panel
//=============================================================================
//

// ==========================================================================
// 0. Variables
// ==========================================================================

$panel-item-border: 1px solid $border !default;

$panel-heading-background-color: $background !default;
$panel-heading-color: $text-strong !default;
$panel-heading-line-height: 1.25 !default;
$panel-heading-padding: 0.5em 0.75em !default;
$panel-heading-radius: $radius !default;
$panel-heading-size: 1.25em !default;
$panel-heading-weight: $weight-light !default;

$panel-tab-border-bottom: 1px solid $border !default;
$panel-tab-active-border-bottom-color: $link-active-border !default;
$panel-tab-active-color: $link-active !default;

$panel-list-item-color: $text !default;
$panel-list-item-hover-color: $link !default;

$panel-block-color: $text-strong !default;
$panel-block-hover-background-color: $background !default;
$panel-block-active-border-left-color: $link !default;
$panel-block-active-color: $link-active !default;
$panel-block-active-icon-color: $link !default;

$panel-icon-color: $text-light !default;

// ==========================================================================
// 1. Panel
// ==========================================================================

.panel {
  font-size: $size-normal;
  &:not(:last-child) {
    margin-bottom: 1.5rem; } }

.panel-heading,
.panel-tabs,
.panel-block {
  border-bottom: $panel-item-border;
  border-left: $panel-item-border;
  border-right: $panel-item-border;
  &:first-child {
    border-top: $panel-item-border; } }

.panel-heading {
  background-color: $panel-heading-background-color;
  border-radius: $panel-heading-radius $panel-heading-radius 0 0;
  color: $panel-heading-color;
  font-size: $panel-heading-size;
  font-weight: $panel-heading-weight;
  line-height: $panel-heading-line-height;
  padding: $panel-heading-padding; }

.panel-tabs {
  align-items: flex-end;
  display: flex;
  font-size: 0.875em;
  justify-content: center;
  a {
    border-bottom: $panel-tab-border-bottom;
    margin-bottom: -1px;
    padding: 0.5em;
    // Modifiers
    &.is-active {
      border-bottom-color: $panel-tab-active-border-bottom-color;
      color: $panel-tab-active-color; } } }

.panel-list {
  a {
    color: $panel-list-item-color;
    &:hover {
      color: $panel-list-item-hover-color; } } }

.panel-block {
  align-items: center;
  color: $panel-block-color;
  display: flex;
  justify-content: flex-start;
  padding: 0.5em 0.75em;
  input[type="checkbox"] {
    margin-right: 0.75em; }
  & > .control {
    flex-grow: 1;
    flex-shrink: 1;
    width: 100%; }
  &.is-wrapped {
    flex-wrap: wrap; }
  &.is-active {
    border-left-color: $panel-block-active-border-left-color;
    color: $panel-block-active-color;
    .panel-icon {
      color: $panel-block-active-icon-color; } } }

a.panel-block,
label.panel-block {
  cursor: pointer;
  &:hover {
    background-color: $panel-block-hover-background-color; } }

.panel-icon {
  @include fa(14px, 1em);
  color: $panel-icon-color;
  margin-right: 0.75em;
  .fa {
    font-size: inherit;
    line-height: inherit; } }
