// TABLE OF CONTENTS
//=============================================================================
// 0. Variables
// 1. Section
//=============================================================================
//

// ==========================================================================
// 0. Variables
// ==========================================================================

$section-padding: 3rem 1.5rem !default;
$section-padding-medium: 9rem 1.5rem !default;
$section-padding-large: 18rem 1.5rem !default;

// ==========================================================================
// 1. Section
// ==========================================================================

.section {
  padding: $section-padding;
  // Responsiveness
  @include desktop {
    // Sizes
    &.is-medium {
      padding: $section-padding-medium; }
    &.is-large {
      padding: $section-padding-large; } } }
