// TABLE OF CONTENTS
//=============================================================================
// 0. Variables
// 1. Footer
//=============================================================================
//

// ==========================================================================
// 0. Variables
// ==========================================================================

$footer-background-color: $white-bis !default;

// ==========================================================================
// 1. Footer
// ==========================================================================

.footer {
  background-color: $footer-background-color;
  padding: 3rem 1.5rem 6rem; }
