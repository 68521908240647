// TABLE OF CONTENTS
//=============================================================================
// 0. Spin around
//=============================================================================
//

// ==========================================================================
// 0. Spin around
// ==========================================================================

@keyframes spinAround {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(359deg); } }
