// TABLE OF CONTENTS
//=============================================================================
// 0. Main container
// 1. Components
// 2. Containers
//=============================================================================
//

// ==========================================================================
// 0. Main container
// ==========================================================================

.hero {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .navbar {
    background: none; }
  .tabs {
    ul {
      border-bottom: none; } }
  // Colors
  @each $name, $pair in $colors {
    $color: nth($pair, 1);
    $color-invert: nth($pair, 2);
    &.is-#{$name} {
      background-color: $color;
      color: $color-invert;
      a:not(.button):not(.dropdown-item):not(.tag),
      strong {
        color: inherit; }
      .title {
        color: $color-invert; }
      .subtitle {
        color: rgba($color-invert, 0.9);
        a:not(.button),
        strong {
          color: $color-invert; } }
      .navbar-menu {
        @include touch {
          background-color: $color; } }
      .navbar-item,
      .navbar-link {
        color: rgba($color-invert, 0.7); }
      a.navbar-item,
      .navbar-link {
        &:hover,
        &.is-active {
          background-color: darken($color, 5%);
          color: $color-invert; } }
      .tabs {
        a {
          color: $color-invert;
          opacity: 0.9;
          &:hover {
            opacity: 1; } }
        li {
          &.is-active a {
            opacity: 1; } }
        &.is-boxed,
        &.is-toggle {
          a {
            color: $color-invert;
            &:hover {
              background-color: rgba($black, 0.1); } }
          li.is-active a {
            &,
            &:hover {
              background-color: $color-invert;
              border-color: $color-invert;
              color: $color; } } } }
      // Modifiers
      &.is-bold {
        $gradient-top-left: darken(saturate(adjust-hue($color, -10deg), 10%), 10%);
        $gradient-bottom-right: lighten(saturate(adjust-hue($color, 10deg), 5%), 5%);
        background-image: linear-gradient(141deg, $gradient-top-left 0%, $color 71%, $gradient-bottom-right 100%);
        @include mobile {
          .navbar-menu {
            background-image: linear-gradient(141deg, $gradient-top-left 0%, $color 71%, $gradient-bottom-right 100%); } } }
      // Responsiveness
      // +mobile
      //   .nav-toggle
      //     span
      //       background-color: $color-invert
      //     &:hover
      //       background-color: rgba($black, 0.1)
      //     &.is-active
      //       span
      //         background-color: $color-invert
      //   .nav-menu
      //     .nav-item
 } }      //       border-top-color: rgba($color-invert, 0.2)
  // Sizes
  &.is-small {
    .hero-body {
      padding-bottom: 1.5rem;
      padding-top: 1.5rem; } }
  &.is-medium {
    @include tablet {
      .hero-body {
        padding-bottom: 9rem;
        padding-top: 9rem; } } }
  &.is-large {
    @include tablet {
      .hero-body {
        padding-bottom: 18rem;
        padding-top: 18rem; } } }
  &.is-halfheight,
  &.is-fullheight {
    .hero-body {
      align-items: center;
      display: flex;
      & > .container {
        flex-grow: 1;
        flex-shrink: 1; } } }
  &.is-halfheight {
    min-height: 50vh; }
  &.is-fullheight {
    min-height: 100vh; } }

// ==========================================================================
// 1. Components
// ==========================================================================

.hero-video {
  @include overlay;
  overflow: hidden;
  video {
    left: 50%;
    min-height: 100%;
    min-width: 100%;
    position: absolute;
    top: 50%;
    transform: translate3d(-50%, -50%, 0); }
  // Modifiers
  &.is-transparent {
    opacity: 0.3; }
  // Responsiveness
  @include mobile {
    display: none; } }

.hero-buttons {
  margin-top: 1.5rem;
  // Responsiveness
  @include mobile {
    .button {
      display: flex;
      &:not(:last-child) {
        margin-bottom: 0.75rem; } } }
  @include tablet {
    display: flex;
    justify-content: center;
    .button:not(:last-child) {
      margin-right: 1.5rem; } } }


// ==========================================================================
// 2. Containers
// ==========================================================================

.hero-head,
.hero-foot {
  flex-grow: 0;
  flex-shrink: 0; }

.hero-body {
  flex-grow: 1;
  flex-shrink: 0;
  padding: 3rem 1.5rem; }
