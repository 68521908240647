
// TABLE OF CONTENTS
//=============================================================================
// 0. Variables
// 1. Tags
// 2. Tag
//=============================================================================
//

// ==========================================================================
// 0. Variables
// ==========================================================================

$tag-background-color: $background !default;
$tag-color: $text !default;
$tag-radius: $radius !default;
$tag-delete-margin: 1px !default;

// ==========================================================================
// 1. Tags
// ==========================================================================

.tags {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  .tag {
    margin-bottom: 0.5rem;
    &:not(:last-child) {
      margin-right: 0.5rem; } }
  &:last-child {
    margin-bottom: -0.5rem; }
  &:not(:last-child) {
    margin-bottom: 1rem; }
  &.has-addons {
    .tag {
      margin-right: 0;
      &:not(:first-child) {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0; }
      &:not(:last-child) {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0; } } }
  &.is-centered {
    justify-content: center;
    .tag {
      margin-right: 0.25rem;
      margin-left: 0.25rem; } }
  &.is-right {
    justify-content: flex-end;
    .tag {
      &:not(:first-child) {
        margin-left: 0.5rem; }
      &:not(:last-child) {
        margin-right: 0; } } } }

// ==========================================================================
// 2. Tag
// ==========================================================================

.tag:not(body) {
  align-items: center;
  background-color: $tag-background-color;
  border-radius: $tag-radius;
  color: $tag-color;
  display: inline-flex;
  font-size: $size-small;
  height: 2em;
  justify-content: center;
  line-height: 1.5;
  padding-left: 0.75em;
  padding-right: 0.75em;
  white-space: nowrap;
  .delete {
    margin-left: 0.25rem;
    margin-right: -0.375rem; }
  // Colors
  @each $name, $pair in $colors {
    $color: nth($pair, 1);
    $color-invert: nth($pair, 2);
    &.is-#{$name} {
      background-color: $color;
      color: $color-invert; } }
  // Sizes
  &.is-medium {
    font-size: $size-normal; }
  &.is-large {
    font-size: $size-medium; }
  .icon {
    &:first-child:not(:last-child) {
      margin-left: -0.375em;
      margin-right: 0.1875em; }
    &:last-child:not(:first-child) {
      margin-left: 0.1875em;
      margin-right: -0.375em; }
    &:first-child:last-child {
      margin-left: -0.375em;
      margin-right: -0.375em; } }
  // Modifiers
  &.is-delete {
    margin-left: $tag-delete-margin;
    padding: 0;
    position: relative;
    width: 2em;
    &::before,
    &::after {
      background-color: currentColor;
      content: "";
      display: block;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translateX(-50%) translateY(-50%) rotate(45deg);
      transform-origin: center center; }
    &::before {
      height: 1px;
      width: 50%; }
    &::after {
      height: 50%;
      width: 1px; }
    &:hover,
    &:focus {
      background-color: darken($tag-background-color, 5%); }
    &:active {
      background-color: darken($tag-background-color, 10%); } }
  &.is-rounded {
    border-radius: $radius-rounded; } }

a.tag {
  &:hover {
    text-decoration: underline; } }
